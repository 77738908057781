import React from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {DateConfigType} from 'types/State'
import {$PropertyType} from 'utility-types'

import {WEEKENDS_INDEXES} from '@daedalus/core/src/datePicker/config'

interface Props {
  weekdaysShort: $PropertyType<DateConfigType, 'weekdaysShort'>
  firstDayOfWeek: $PropertyType<DateConfigType, 'firstDayOfWeek'>
  shouldHighlightWeekend?: boolean
}

const WeekDaysContainer = styled('div')(
  ({theme}) => css`
    position: relative; /* ensure z-index sticks */
    padding: ${theme.layout.spacing.s300}px ${theme.layout.spacing.s500}px;
    border-top: 1px solid ${theme.colors.border.neutral.c100};
    box-shadow: ${theme.shadows.action};
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: ${theme.layout.spacing.s300}px;
    width: 100%;
  `
)

const WeekDay = styled('div')<{isWeekend?: boolean}>(
  ({theme, isWeekend = false}) => css`
    display: table-cell;
    text-align: center;
    color: ${theme.colors.content.neutral.c600};
    ${theme.typography.text.bodyS}
    ${isWeekend &&
    css`
      background-color: ${theme.colors.background.neutral.c100};
      border-radius: ${theme.layout.radius.sm}px;
    `}
  `
)

/* mimics the layout + styling of DatePickerContent to ensure the columns line up */
export const WeekDays = ({
  weekdaysShort,
  firstDayOfWeek,
  shouldHighlightWeekend
}: Props) => {
  // Shift to week start
  const weekEnd = (weekdaysShort || []).slice(0, firstDayOfWeek)
  const weekStart = (weekdaysShort || []).slice(firstDayOfWeek)
  const weekdays = [...weekStart, ...weekEnd]

  // Find indexes of weekend days for the shifted weekdays
  const weekendsIndexes = WEEKENDS_INDEXES.map(index =>
    weekdays.findIndex(weekday => weekday === weekdaysShort[index])
  )
  return (
    <WeekDaysContainer>
      {weekdays.map((day, index) => (
        <WeekDay
          key={day}
          isWeekend={weekendsIndexes.includes(index) && shouldHighlightWeekend}
        >
          {day}
        </WeekDay>
      ))}
    </WeekDaysContainer>
  )
}
