import React, {ReactNode} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {toggle} from 'opticks'

import {Button, Variant} from '@daedalus/atlas/Button'
import {Divider} from '@daedalus/atlas/Divider'
import {Icon} from '@daedalus/atlas/Icon'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {SelectedDatesLabel} from './SelectedDatesLabel'

type FooterProps = {
  children?: ReactNode
  customMessage?: ReactNode
  disabled?: boolean
  onClick: () => void
  checkIn?: string | null
  checkOut?: string | null
  legendSlot?: ReactNode
}

const DatesWrapper = styled('div')(
  ({theme}) => css`
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.layout.spacing.s300}px;
    > div:only-of-type {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  `
)

const Footer = styled('div')(
  ({theme}) => css`
    width: 100%;
    padding: ${theme.layout.spacing.s400}px ${theme.layout.spacing.s400}px;
  `
)

export const DatePickerFooter = ({
  children,
  customMessage,
  disabled,
  onClick,
  checkIn,
  checkOut,
  legendSlot
}: FooterProps) => {
  const buttonMessage = customMessage || (
    <FormattedMessageWrapper id="search" defaultMessage="Search" />
  )

  return (
    <>
      <Divider />
      <Footer data-id="SearchOverlayFooter">
        <DatesWrapper>
          {!!legendSlot && legendSlot}
          <SelectedDatesLabel checkIn={checkIn} checkOut={checkOut} />
        </DatesWrapper>
        <Button
          fullWidth
          variant={toggle<Variant[]>(
            'b4f4f3cc-website-restyle-v3',
            'primary',
            'primaryGradient'
          )}
          type="submit"
          dataId="SearchButton"
          disabled={disabled}
          onClick={onClick}
          iconStart={!customMessage && <Icon name="Search" />}
        >
          <span>{buttonMessage}</span>
          <span>{children && <> · {children}</>}</span>
        </Button>
      </Footer>
    </>
  )
}
